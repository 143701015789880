//variables
$font_family1: 'Barlow', sans-serif;
$color_white:#ffffff;
$color_black:#000000;
//$color1: #eda91e;
//$color2: #283264;
$color3: #f7f7f7;
$color_font1:$color_black;
$color_font2:#696969;
$border-color: $color3;
$border-color2: #e6e6e6;
$color_err: #e30016;

// zmienne do kolorowania szablonu
$color1: var(--primary_color);
$color2: var(--secondary_color);
$header_font_colour : var(--header_font_colour);
$primary_color_font : var(--primary_color_font);
$secondary_color_font : var(--secondary_color_font);

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin bg_pos{
    background-position: center;
    background-repeat: no-repeat;
}

@mixin horizonat_inline{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin corner{
    clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 0 100%);
}
@mixin no_corner{
    clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%);
}
//default_font
.default_font{
    font-size: 16px;
    line-height: 24px;
    color: $color_font2;
    @media only screen and (max-width: 767px) {
        font-size: 13px;
        line-height: 21px;
    }
    h1{
        font-size: 42px;
        line-height: 52px;
        @media only screen and (max-width: 1500px) {
            font-size: 32px;
            line-height: 42px;
        }
    }
    h2{
        font-size: 32px;
        line-height: 42px;
        @media only screen and (max-width: 1500px) {
            font-size: 26px;
            line-height: 36px;
        }
    }
    h3{
        font-size: 21px;
        line-height: 30px;
    }
    h4{
        font-size: 20px;
        line-height: 30px;
    }
    h5{
        font-size: 19px;
        line-height: 29px;
    }
    h6{
        font-size: 18px;
        line-height: 28px;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol{
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        color: $color_font1;
    }
    img{
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    a{
        color: $color_font2;
        @include anim;
        &:hover{
            color: $color2;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
    }
    ol{
        padding-left: 18px;
    }
}
.page{
    min-height: 0px !important;
    overflow: hidden;
    position: relative;
}
//default_element
.btn1{
    background-color: $color1;
}
.btn2{
    background-color: $color2;
}
.btn1, .btn2 {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: $color_white;
    padding-right: 79px;
    padding-left: 41px;
    background-repeat: no-repeat;
    background-position: right 40px center;
    background-image: url("./../template_img/more_arr1.png");
    position: relative;
    line-height: 62px;
    @media only screen and (max-width: 767px) {
        margin-right: 0;
        width: 100%;
        font-size: 13px;
        line-height: 50px;
    }
    &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        @include anim;
        border-bottom: 2px solid $color_white;
    }
    &:hover, &.hover {
        text-decoration: none;
        color: $color_white;
        &:after{
            width: 100%;
        }
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
        background-image: none;
        padding-right: 40px;
        color: $color_font1 !important;
    }
}


.btn3 {
    font-size: 16px;
    font-weight: 700;
    color: $color_font2;
    display: inline-block;
    padding-right: 37px;
    background-repeat: no-repeat;
    background-position: right 0px center;
    background-image: url("./../template_img/more_arr2.png");
    position: relative;
    @include anim;
    text-transform: uppercase;
    letter-spacing: 1px;
    &.type2{
        padding-right: 34px;
        font-size: 13px;
        background-image: url("./../template_img/more_arr3.png");
        &:hover{
            &:after{
                display: none;
            }
        }
    }
    &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        @include anim;
        border-bottom: 2px solid $color_font2;
    }
    &:hover, &.hover {
        text-decoration: none;
        color: $color_font2;
        &:after{
            width: 100%;
        }
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
        background-image: none;
        padding-right: 40px;
        color: $color_font1 !important;
    }
}

//default_form
.holder{
    margin-bottom: 25px;
    .text-right{
        p{
            text-transform: uppercase;
            color: #b8b8b8;
            font-size: 13px;
            font-weight: 600;
            @media only screen and (max-width: 767px) {
                font-size: 11px;
                padding-top: 3px;
            }
        }
    }
}
label{
    font-size: 16px;
    color: $color_font1;
    font-weight: 500;
}
.default_form {
    input[type="text"] {
        width: 100%;
        height: 50px;
        background: $color_white;
        color: $color_font1;
        font-size: 16px;
        border: none;
        padding: 0 20px;
        border: 1px solid #f2eee9;
    }

    textarea {
        width: 100%;
        min-height: 150px;
        background: $color_white;
        color: $color_font1;
        font-size: 16px;
        border: none;
        padding: 20px;
        resize: none;
        border: 1px solid #f2eee9;
    }

    .checkbox_image_box{
        width: 20px;
        height: 20px;
        border: 2px solid #f2eee9;
        cursor: pointer;
    }
}
.captcha_box{
    padding-top: 15px;
    margin-bottom: 60px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
}
.rules{
    .default_form{
        padding-top: 0;
    }
    > div{
        display: inline-block;
        vertical-align: top;
        &.check_desc{
            width: calc(100% - 20px);
            padding-left: 17px;
            font-size: 13px;
            color: $color_font1;
            padding-top: 1px;
            font-weight: 500;
            a{
                font-weight: 500;
                color: $color1;
            }
        }
    }
}


.row{
    &.inline_row{
        > div{
            display: inline-block;
            vertical-align: middle;
            float: none;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
    &.flex_row{
        display: flex;
        @media only screen and (max-width: 991px) {
            display: block;
        }
    }
}

body{
    font-family: $font_family1;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

button{
    background: transparent;
    border: none;
    padding: 0;
}


.page_header{
    .logo_box{
        .rwd_btn{
            display: none;
        }
        @media only screen and (max-width: 991px) {
            img{
                max-height: 65px;
                width: auto;
                max-width: unset;
            }
        }
        @media only screen and (max-width: 767px) {
            position: relative;
            img{
                max-height: 50px;
                width: auto;
                max-width: unset;
            }
            button{
                width: 30px;
                position: absolute;
                display: block;
                top: 15px;
                right: 0;
                display: block !important;
                > span{
                    background: $color1;
                    height: 3px;
                    margin-bottom: 3px;
                    width: 100%;
                    display: block;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .page_header_top{
        padding-top: 11.5px;
        padding-bottom: 11.5px;
        background: $color1;
        font-size: 13px;
        //color: $color_white;
        color: $header_font_colour;
        @media only screen and (max-width: 767px) {
            .inline_row{
                > div{
                    width: 100%;
                }
            }
        }
        p,a{
            font-weight: inherit;
            color: inherit;
        }
    }
    .page_header_bottom{
        padding-top: 18px;
        padding-bottom: 18px;
        background: $color_white;
        position: relative;
        @media only screen and (max-width: 767px) {
            padding: 20px 0;
            .page_menu_holder{
                display: none;
            }
        }
    }
    .fast_contact_holder{
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
    }
    .page_header_parent_box{
        > div{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .fast_contact_box{
        span,a{
            font-weight: 600;
        }
        span{
            margin-right: 35px;
            span{
                margin: 0;
            }
            @media only screen and (max-width: 1400px) {
                margin-right: 25px;
            }
            @media only screen and (max-width: 1300px) {
                span{
                    display: none;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .contrast_box{
        margin-right: 40px;
        font-weight: 500;
        @media only screen and (max-width: 1200px) {
            margin-right: 20px;
        }
        @media only screen and (max-width: 991px) {
            display: none !important;
        }
        img{
            margin-left: 10px;
        }
    }
    .fonts_box{
        margin-right: 60px;
        font-weight: 500;
        @media only screen and (max-width: 991px) {
            display: none !important;
        }
        .plugin_font_sizes{
            padding-left: 10px;
            button{
                padding-right: 3px;
                margin-right: 3px;
                border-right: 2px solid $color_white;
                line-height: 16px;
                font-weight: 500;
                &:last-child{
                    padding-right: 0;
                    margin-right: 0;
                    border-right: 0;
                }
            }
        }
        > div{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .social_box{
        margin-right: 40px;
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
        a{
            display: inline-block;
            vertical-align: middle;
            margin-right: 18px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .go_to_box{
            width: 100%;
        }
    }
    .dropdown{
        &.go_to{
            .btn{
                width: 103px;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        &.open{
            background-color: transparent;
            .btn{
                background-color: transparent;
                color: $color_white;
            }
            .dropdown-menu{
                top: 100%;
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }
    .btn{
        borde-radius:5px;
        //border: 1px solid #f1ba4b;
        border: 1px solid $color_white;
        background: transparent;
        text-align: left;
        padding: 0 20px 0 20px;
        position: relative;
        color: $color_white;
        font-size: 11px;
        line-height: 25px;
        font-weight: 500;
        &:focus{
            outline: auto;
            outline-color: rgba(0,85,192,1);
        }
        .caret{
            height: 100%;
            width: 28px;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            @include bg_pos;
            background-position: right 15px center;
            background-image: url("./../template_img/sm_select_arr.png");
            @media only screen and (max-width: 767px) {
                background-position: right 15px center;
            }
        }
    }
    .dropdown-menu{
        top: 0%;
        min-width: 0;
        display: block;
        visibility: hidden;
        opacity: 0;
        @include anim;
        width: 100%;
        text-align: left;
        padding: 0;
        border: none;
        max-height: 200px;
        overflow-y: auto;
        > li{
            width: 100%;
            cursor: pointer;
            > a{
                font-size: 11px;
                line-height: 15px;
                @include anim;
                position: relative;
                padding: 5px 15px;
                color: $color_font1;
                white-space: normal;
                display: inline-block;
                &:hover{
                    color: $color_white;
                    background: $color2;

                    .lang_holder{
                        color: $color_white;
                    }
                }
            }
        }
    }
    .menu_box{
        text-align: right;
        @media only screen and (max-width: 767px) {
           text-align: center;
            padding-top: 20px;
        }
        .menu{
            display: inline-block;
            vertical-align: middle;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    vertical-align: top;
                    margin-right: 60px;
                    &:last-child{
                        margin-right: 0;
                    }
                    @media only screen and (max-width: 1720px) {
                        margin-right: 45px;
                    }
                    @media only screen and (max-width: 1500px) {
                        margin-right: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 20px;
                    }
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid $border-color;
                        margin-right: 0;
                        &:last-child{
                            padding-bottom: 0;
                            border-bottom: 0;
                            margin-bottom: 0;
                        }
                    }
                    > div{
                        > a{
                            font-size: 16px;
                            @include anim;
                            text-transform: uppercase;
                            color: $color_font2;
                            font-weight: 600;
                            @media only screen and (max-width: 1400px) {
                                font-size: 14px;
                            }
                            @media only screen and (max-width: 991px) {
                                font-size: 12px;
                            }
                            @media only screen and (max-width: 767px) {
                                font-size: 16px;
                            }
                            &:hover{
                                color: $color1;
                            }
                            &.active{
                                color: $primary_color_font;
                            }
                        }
                    }
                }
            }
        }
        .page_header_btns{
            display: inline-block;
            vertical-align: middle;
            padding-left: 50px;
            .page_header_btn{
                display: inline-block;
                vertical-align: middle;
                font-size: 16px;
                line-height: 47px;
                padding: 0 30px;
                color: $color_white;
                background: $color2;
                font-weight: 600;
                text-transform: uppercase;
                margin-right: 50px;
            }
            .search_btn{
                width: 45px;
                height: 45px;
                border: 2px solid #f0f0f0;
                display: inline-block;
                vertical-align: middle;
                @include bg_pos;
                background-image: url("./../template_img/search_icon1.png");
                border-radius: 50%;
            }
        }
    }
}

.slider{
    .slides{
        //height: calc(100% - 100px);
        background: $color_black;
        position: relative;
        @media only screen and (max-width: 1500px) {
            height: calc(100% - 70px);
        }
        > ul{
            @include fill;
            > li{
                @include fill;
                .image{
                    @include fill;
                    @include bg_pos;
                    background-size: cover;
                    opacity: 0.6;
                }
                .slider_content{
                    @include vertical;
                    @include fill;
                    z-index: 2;
                    color: $color_white;
                    font-size: 42px;
                    line-height: 62px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 32px;
                        line-height: 52px;
                    }
                    @media only screen and (max-width: 1200px) {
                        font-size: 22px;
                        line-height: 42px;
                    }
                    @media only screen and (max-width: 991px) {
                        font-size: 16px;
                        line-height: 36px;
                    }
                    p{
                        font-weight: 600;
                    }
                    h1{
                        text-transform: uppercase;
                        font-weight: 800;
                        font-size: 62px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 52px;
                        }
                        @media only screen and (max-width: 1200px) {
                            font-size: 42px;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 36px;
                        }
                    }
                    .slide_btns{
                        padding-top: 50px;
                        @media only screen and (max-width: 1200px) {
                            padding-top: 30px;
                        }
                        a{
                            margin-right: 40px;
                            @media only screen and (max-width: 767px) {
                                margin-right: 0;
                                width: 100%;
                                text-align: left;
                                margin-bottom: 15px;
                            }
                            &:last-child{
                                margin-bottom: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .slider_dots{
            position: absolute;
            left: 0;
            bottom: 60px;
            width: 100%;
            text-align: center;
            z-index: 2;
            @media only screen and (max-width: 1500px) {
                bottom: 40px;
            }
            .slide_dot{
                width: 10px;
                height: 10px;
                background: $color_white;
                border-radius: 50%;
                margin-right: 20px;
                cursor: pointer;
                &.on{
                    background: $color1;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .information_bar{
        height: 100px;
        width: 100%;
        background: $color2;
        @include vertical;
        @media only screen and (max-width: 1500px) {
            height: 70px;
        }
        > div{
            width: 100%;
            text-align: center;
        }
        p{
            color: $color_white;
            font-weight: 700;
            font-size: 21px;
            line-height: 21px;
            display: inline-block;
            padding-left: 30px;
            background-repeat: no-repeat;
            background-position: left center;
            background-image: url("./../template_img/warning_icon1.png");
            @media only screen and (max-width: 1500px) {
                font-size: 16px;
            }
        }
    }
}

.section_header{
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
       text-align: center;
        .text-right{
            text-align: center;
            padding-top: 15px;
        }
    }
}

.element_list{
    &.one_row{
        > ul{
            > li{
                width: 100%;
            }
        }
    }
    &.two_row{
        > ul{
            > li{
                width: 50%;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    &.four_row{
        > ul{
            > li{
                width: 25%;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    > ul{
        margin: 0 -15px;
        > li{
            padding: 0 15px;
            width: 33.33%;
            margin-bottom: 30px;
            display: inline-block;
            vertical-align: top;
            @media only screen and (max-width: 991px) {
                width: 50%;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            &:hover{
                a{
                    text-decoration: none;
                }
            }
        }
    }
}

.from_to_date{
    background: $color1;
    margin-top: 40px;
    margin-left: 40px;
    display: inline-block;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 1500px) {
        margin-top: 30px;
        margin-left: 30px;
    }
    > div{
        display: inline-block;
        vertical-align: middle;
        .date{
            margin: 0 !important;
        }
        &.separate{
            width: 24px;
            height: 2px;
            background: $color_white;
        }
    }
}

.date{
    background: $color1;
    color: $color_white;
    font-size: 24px;
    width: 65px;
    height: 65px;
    position: relative;
    z-index: 2;
    @include vertical;
    p{
        text-align: center;
        width: 100%;
        font-weight: 700;
        margin-bottom: 7px;
        span{
            font-weight: inherit;
            text-transform: uppercase;
            font-size: 11px;
            display: block;
        }
    }
}


.element_box{
    &:hover{
        .image_holder{
            .image{
                transform: scale(1.3) ;
            }
        }
        .desc_holder{
            .desc{
                background-color: $color2;
                border-color: $color2;
                .element_box_header{
                    h3{
                        color: $color_white;
                    }
                }
                .default_font{
                    color: $color_white;
                }
                .btn3{
                    color: $color_white;
                    background-image: url("./../template_img/more_arr3w.png");
                }
            }
        }
    }
    .image_holder{
        width: 100%;
        height: 0;
        padding-bottom: 66%;
        position: relative;
        overflow: hidden;
        .date{
            margin-top: 40px;
            margin-left: 40px;
            @media only screen and (max-width: 1500px) {
                margin-top: 30px;
                margin-left: 30px;
            }
            @media only screen and (max-width: 1200px) {
                margin-left: 30px;
            }
        }
        .image{
            @include fill;
            @include bg_pos;
            @include anim;
            background-size: cover;
        }
    }
    .desc_holder{
        margin-top: -30px;
        padding-right: 30px;
        position: relative;
        z-index: 2;
        .desc{
            @include anim;
            border: 2px solid $border-color;
            padding:0 40px 30px 40px;
            background-color: $color_white;

            @media only screen and (max-width: 1200px) {
                padding: 0 30px 30px 30px;
            }
            h3{


            }

            .date{
                margin-top: -32.5px;
            }
            .element_box_header{
                margin-top: 25px;
                h3{
                    @include anim;
                    color: $primary_color_font;
                }
            }
            > .default_font{
                color: $secondary_color_font;
                padding-top: 20px;
                @include anim;

                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .btn3{
                margin-top: 20px;
            }
        }
    }
}

.news, .main_news, .main_messages, .main_events, .tiles, .main_download{
    background: $color3;
}

.news, .messages, .see_also{
    padding: 100px 0 70px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 20px 0;
    }
}

.about{
    padding: 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
}

.main_news, .main_messages, .text_page{
    padding: 60px 0 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 40px 0 50px 0;
    }
}

.tiles, .main_download, .contact{
    padding: 60px 0 70px 0;
    @media only screen and (max-width: 1500px) {
        padding: 40px 0 50px 0;
    }
}

.main_events{
    padding: 60px 0 70px 0;
    .calendar{
        margin-bottom: 40px;
        .mycalendar2_slider {
            .slide{
                &.months{
                    border: 1px solid #f2eee9;
                    margin-bottom: 0;
                    box-shadow: none;
                    clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 0 100%);
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        clip-path: none;
                    }
                    &:after{
                        content: '';
                        width: 100px;
                        border: 1px solid #f2eee9;
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: rotate(45deg) translate(25px, -9px);
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                    .month{
                        @include anim;
                        &.selected_active, &:hover{
                            background-color: $color1 !important;
                            .year, .mounth{
                                color: $color_white;
                            }
                        }
                    }
                    .year{
                        @include anim;
                        font-weight: 500;
                        font-size: 10px;
                        color: #767676;
                    }
                    .mounth{
                        @include anim;
                        font-weight: 500;
                        line-height: 30px;
                        color: #767676;
                    }
                }
                &.days{
                    border: 1px solid #f2eee9;
                    box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.05);
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 65%, rgba(243,243,243,1) 100%);
                    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 65%, rgba(243,243,243,1) 100%);
                    background: linear-gradient(0deg, rgba(255,255,255,1) 65%, rgba(243,243,243,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f3f3f3",GradientType=1);
                    .day{
                        @include anim;
                        .name{
                            @include anim;
                            color: #767676;
                        }
                        .no{
                            @include anim;
                            color: #767676;
                        }
                        &:hover{
                            background-color: $color1 !important;
                            .name, .no{
                                color: $color_white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.message_box{
    padding: 40px;
    border: 2px solid $border-color;
    @include anim;
    background: $color_white;
    @media only screen and (max-width: 767px) {
        padding: 30px 20px;
    }
    &.with_icon{
        background: $color3;
        &:hover{
            .message_box_bottom{
                .desc{
                    .default_font{
                        color: $color_white;
                    }
                }
            }
        }
    }
    .message_box_top{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        .date{
            margin-bottom: 0;
        }
        .icon{
            width: 65px;
            height: 65px;
            @include bg_pos;
            background-size: contain;
        }
    }
    .message_box_bottom{
        .desc{
            .default_font{
                color: $color_font1;
                @include anim;
                p{
                    font-weight: 500;
                }
            }
        }
    }
    &:hover{
        background: $color1;
        .date{
            background: $color2;
        }
        h3, .default_font{
            color: $color_white;
        }
        .btn3{
            color: $color_white;
            background-image: url("./../template_img/more_arr3w.png");
        }
    }
    .date, .default_font, h3{
        margin-bottom: 25px;
        @include anim;
    }
}

.see_also{
    background: $color3;
}

.icon_box{
    height: 80px;
    display: flex;
    &:hover{
        > div{
            &.icon{
                background-color: $color2;
            }

        }
    }
    > div{
        &.icon{
            @include anim;
            @include bg_pos;
            background-color: $color1;
            width: 80px;
        }
        &.desc{
            width: calc(100% - 80px);
            @include vertical;
            background:$color_white;
            > div{
                color: $color_font1;
                width: 100%;
                padding: 0 28px;
            }
        }
    }
}

.about{
    .row{
        margin-left: -30px;
        margin-right: -30px;
        > div{
            padding: 0 30px;
        }
    }
    .desc{
        @media only screen and (max-width: 991px) {
            margin-bottom: 40px;
        }
        .title{
            color: $color_font1;
            margin-bottom: 30px;
            h2{
                font-size: 24px;
                line-height: 34px;
            }
        }
        .default_font{
            color: $color_font1;
        }
    }
    .image{
        width: 100%;
        height: 0;
        padding-bottom: 62%;
        @include bg_pos;
        background-size: cover;
    }
}

.arr{
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid $border-color;
    @include anim;
    @include bg_pos;
    &:hover{
        background-color: $border-color;
    }
    &.arr_left{
        background-image: url("./../template_img/arr_left.png");
        margin-right: 20px;
    }
    &.arr_right{
        background-image: url("./../template_img/arr_right.png");
    }
}

.carousel_content{
    overflow: hidden;
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    > ul{
        position: relative;
        white-space: nowrap;
        margin: 0 -15px;
        > li{
            padding: 0 15px;
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            width: 50%;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            &:hover{
                a{
                    text-decoration: none;
                }
            }
        }
    }
}

.events{
    padding-bottom: 100px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    .calendar{
        -webkit-box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.05);
        box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.05);
        height: 100%;
    }
    .calendar_event_info{
        padding: 20px 40px 70px 40px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 0;
        }
        @media only screen and (max-width: 1200px) {
            padding: 20px;
            padding-top: 0;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        .default_font{
            color: $color_font2;
            .row{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .text-right{
            padding-top: 30px;
        }
    }
}

.mycalendar2_datepicker {
    .calendar_content {
        header.months_change {
            padding: 20.5px 0;
            background: $color2;
            .title {
                vertical-align: middle;
                margin: 0 70px;
                @media only screen and (max-width: 1500px) {
                    margin: 0 50px;
                }
                @media only screen and (max-width: 1500px) {
                    margin: 0 20px;
                }
                .month, .year{
                    font-size: 24px;
                }
            }
            .right{
                vertical-align: middle;
                background-image: url("./../template_img/cal_arr_right.png");
            }
            .left, .right{
                height: 11px;
                width: 14px;
                @include bg_pos;
                svg{
                    display: none;
                }
            }
            .left{
                background-image: url("./../template_img/cal_arr_left.png");
            }
        }
        .days_table{
            padding: 15px 40px;
            @media only screen and (max-width: 1500px) {
                padding: 15px;
            }
            .thead{
                .tr{
                    .td{
                        p{
                            color: $color_font1
                        }
                    }
                }
            }
            .tr {
                .td {
                    p{
                        font-size: 13px;
                        color: $color_font2;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

.banners{
    display: flex;
    height: 444px;
    width: 1700px;
    margin: 0 auto;
    @media only screen and (max-width: 1700px) {
        width: 1480px;
    }
    @media only screen and (max-width: 1480px) {
        width: 1260px;
        height: 400px;
    }
    @media only screen and (max-width: 1260px) {
        width: 100%;
    }
    @media only screen and (max-width: 767px) {
        display: block;
        height: auto;
    }
    a{
        display: block;
        width: 50%;
        position: relative;
        height: 100%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    .banner_box{
        background: $color_black;
        position: relative;
        @include fill;
        @media only screen and (max-width: 767px) {
            position: relative;
            height: 400px;
        }
        &:hover{
            .image{
                opacity: 0.5;
            }
        }
        .image{
            @include fill;
            @include bg_pos;
            background-size: cover;
            opacity: 0.7;
            @include anim;
        }
        .banner_content{
            @include fill;
            @include vertical;
            z-index: 2;
            font-size: 42px;
            line-height: 62px;
            color: $color_white;
            @media only screen and (max-width: 1500px) {
                font-size: 32px;
                line-height: 52px;
            }
            @media only screen and (max-width: 1260px) {
                font-size: 22px;
                line-height: 42px;
                justify-content: center;
            }
            @media only screen and (max-width: 991px) {
                font-size: 16px;
                line-height: 36px;
            }
            > div{
                width: 100%;
                padding-left: 135px;
                @media only screen and (max-width: 1700px) {
                    padding-left: 80px;
                }
                @media only screen and (max-width: 1260px) {
                    padding-left: 0;
                    width: auto;
                }
                @media only screen and (max-width: 767px) {
                    text-align: center;
                }
            }
            p{
                font-weight: 600;
            }
            h2{
                font-size: 62px;
                font-weight: 800;
                background-repeat: no-repeat;
                background-position: left bottom;
                background-image: url("./../template_img/more_arr4.png");
                padding-bottom: 75px;
                @media only screen and (max-width: 1500px) {
                    font-size: 52px;
                }
                @media only screen and (max-width: 1260px) {
                    font-size: 42px;
                }
                @media only screen and (max-width: 991px) {
                    font-size: 36px;
                }
                @media only screen and (max-width: 767px) {
                    background-position: center bottom;
                }
            }
        }
    }
}

.tag{
    margin-bottom: 30px;
    p{
        line-height: 30px;
        display: inline-block;
        padding: 0 20px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $color_white;
        background: $color1;
    }
}


.page_footer{
    padding-top: 100px;
    @media only screen and (max-width: 1500px) {
        padding-top: 50px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 30px;
    }
    .page_footer_top{
        padding-bottom: 100px;
        border-bottom: 1px solid $border-color2;
        font-size: 16px;
        color: $color_font2;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 50px;
            font-size: 14px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            text-align: center;
            padding-bottom: 0px;
            .col-sm-3{
                margin-bottom: 30px;
            }
        }
        p,a {
            color: $color_font2;
            color: inherit;
            font-weight: 600;
        }
        a{
            @include anim;
            &:hover{
                text-decoration: none;
                color: $color1;
            }
        }
        ul{
            >li{
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .page_footer_bottom{
        color: $color_font2;
        font-weight: 500;
        font-size: 13px;
        padding:10px 0 60px 0;
        @media only screen and (max-width: 1500px) {
            padding: 30px 0;
        }
        @media only screen and (max-width: 767px) {
            text-align: center;
            .text-right{
                padding-top: 15px;
                text-align: center;
            }
        }
        span, p{
            font-weight: inherit;
        }
        span{
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
        }
        img{
            margin-left: 18px;
        }
        .icon_svg{
            svg{
              width: 210px;
            }
        }
    }
    .fh{
        text-transform: uppercase;
        color: $color_font1;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 30px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
        @media only screen and (max-width: 767px) {
            margin-bottom: 15px;
        }
        p{
            font-weight: inherit;
        }
    }
    .logo_box{
        margin-bottom: 40px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
    .social_box{
        *{
            vertical-align: middle;
        }
        span{
            font-weight: 500;
            margin-right: 20px;
            @media only screen and (max-width: 991px) {
                margin-right: 0;
                display: block;
                margin-bottom: 10px;
            }
        }
        a{
            margin-right: 15px;
            display: inline-block;
            vertical-align: middle;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.footer_contact_box{
    .f_contact_box{
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
            &:last-child{
                margin-top: 0;
            }
        }
        .icon_svg{
            width: 18px;
            @media only screen and (max-width: 767px) {
                display: block;
                margin: 0 auto 18px auto;
            }
        }
        div,p,a{
            display: inline-block;
            vertical-align: top;
        }
        p,a{
            width: calc(100% - 18px);
            padding-left: 15px;
            @media only screen and (max-width: 767px) {
                padding-left: 0;
                width: 100%;
            }
        }
    }
    a,p{
        //padding-left: 32px;
        background-repeat: no-repeat;
        background-position: left center;
        margin-bottom: 15px;
        display: block;
        //@media only screen and (max-width: 767px) {
        //    padding-left: 0;
        //    padding-top: 32px;
        //    background-position: center top;
        //}
        //&:last-child{
        //    margin-bottom: 0;
        //}
        //&.phone{
        //    background-image: url("./../template_img/contact_icon1.png");
        //}
        //&.mail{
        //    background-image: url("./../template_img/contact_icon2.png");
        //}
        //&.adress{
        //    background-image: url("./../template_img/contact_icon3.png");
        //}
    }
}

section.top{
    position: relative;
    height: 215px;
    @include vertical;
    background: $color_black;
    @media only screen and (max-width: 1500px) {
        height: 180px;
    }
    .container{
        position: relative;
        z-index: 2;
    }
    .date_header{
        .date{
            margin: 0 auto 10px auto;
        }
        .default_font{
            h1{
                font-size: 32px;
                line-height: 42px;
                @media only screen and (max-width: 767px) {
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
    }
    .default_font{
        h1{
            color: $color_white;
            //text-transform: uppercase;
        }
    }
    .image{
        @include fill;
        @include bg_pos;
        background-size: cover;
        opacity: 0.6;
    }
}


.paggination_box{
    width: 100%;
    padding: 16px 42px;
    display: flex;
    margin-top: 50px;
    box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.05);
    background:$color_white;
    @media only screen and (max-width: 1500px) {
        margin-top: 30px;
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 20px;
        margin-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
        display: block;
    }
    .pagi_arr{
        width: 148px;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        button{
            width: 100%;
            font-size: 11px;
            text-transform: uppercase;
            color: $color_font2;
            > span{
                display: inline-block;
                background-repeat: no-repeat;
                line-height: 28px;
                letter-spacing: 2px;
                font-weight: 500;
            }
        }
        &.pagi_arr_left{
            button{
                border-right: 1px solid #d7d9db;
                text-align: left;
                @media only screen and (max-width: 767px) {
                    text-align: center;
                    border-right: none;
                    border-bottom: 1px solid #d7d9db;
                    padding-bottom: 10px;
                }
                > span{
                    padding-left: 30px;
                    background-position: left center;
                    background-image: url("./../turist_img/pagi_arr_left.png");
                }
            }
        }
        &.pagi_arr_right{
            button{
                border-left: 1px solid #d7d9db;
                text-align: right;
                @media only screen and (max-width: 767px) {
                    text-align: center;
                    border-left: none;
                    border-top: 1px solid #d7d9db;
                    padding-top: 10px;
                }
                > span{
                    padding-right: 30px;
                    background-position: right center;
                    background-image: url("./../turist_img/pagi_arr_right.png");
                }
            }
        }
    }
    .pagi_sites{
        width: calc(100% - (2* 148px));
        text-align: center;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 10px 0;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                margin-right: 30px;
                &:last-child{
                    margin-right: 0;
                }
                &.on{
                    a{
                        color: $color_font1;
                        font-weight: 700;
                    }
                }
                a{
                    color: $color_font2;
                    font-size: 15px;
                    line-height: 28px;
                    display: block;
                }
            }
        }
    }
}

.tile_box{
    position: relative;
    background: $color_white;
    height: 260px;
    @include anim;
    @media only screen and (max-width: 1500px) {
        height: 220px;
    }
    @media only screen and (max-width: 1200px) {
        height: 180px;
    }
    @media only screen and (max-width: 991px) {
        height: 220px;
    }
    @media only screen and (max-width: 767px) {
        height: 0;
        padding-bottom: 80%;
    }
    &.with_image{
        .desc{
            > div{
                h2{
                    color: $color_white;
                }
            }
        }
    }
    &:hover{
        background: $color1;
        &.with_image{
            .image_holder{
                .image{
                    opacity: 0.3;
                }
            }
        }
        .desc{
            > div{
                h2{
                    color: $color_white;
                }
            }
        }
    }
    .image_holder{
        @include fill;
        background: $color_black;
        .image{
            @include fill;
            @include anim;
            @include bg_pos;
            background-size: cover;
            opacity: 0.6;
        }
    }
    .desc{
        @include fill;
        z-index: 2;
        @include vertical;
        > div{
            padding: 0 15px;
            text-align: center;
            width: 100%;
            h2{
                font-size: 21px;
                line-height: 31px;
                font-weight: 600;
                color: $primary_color_font;
                @include anim;
            }
        }
    }
}


.gallery_details{
    .details_top{
        margin-bottom: 50px;
        h2{
            margin-bottom: 20px;
            font-weight: 600;
        }
        text-align: center;
    }
    .desc{
        margin-bottom: 60px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
    }
}

.details_pd{
    padding: 0 7%;
    @media only screen and (max-width: 1200px) {
        padding: 0;
    }
}

.grid_gallery{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-gap: 30px;
    grid-auto-rows: minmax(7.813vw, auto);
    grid-auto-flow: dense;
    @media only screen and (max-width: 1500px) {
        grid-gap: 15px;
    }
    .gallery_item{
        position: relative;
        &.large {
            grid-column-end: span 2;
            grid-row-end: span 2;
            @media only screen and (max-width: 767px) {
                grid-column-end: span 4;
                grid-row-end: span 4;
            }
        }
        &.small{
            grid-column-end: span 1;
            grid-row-end: span 1;
            @media only screen and (max-width: 991px) {
                grid-column-end: span 2;
                grid-row-end: span 2;
            }
            @media only screen and (max-width: 767px) {
                grid-column-end: span 4;
                grid-row-end: span 4;
            }
        }
        &:hover{
            .image{
                .cover{
                    top: 0;
                }
            }
        }
    }
    .image{
        @include fill;
        @include bg_pos;
        background-size: cover;
        overflow: hidden;

        .cover{
            @include fill;
            background: rgba(0,0,0,0.7);
            @include vertical;
            @include anim;
            top: 100%;
            > div{
                width: 100%;
                text-align: center;
            }
        }
    }
}

.text_page{
    position: relative;
    min-height: 100vh;
    width: 100%;
    .text_page_header{
        color: $color_font1;
        margin-bottom: 30px;
        text-transform: uppercase;
        @media only screen and (max-width: 767px) {
            text-align: center;
        }
    }
    .desc{
        .default_font{
            color: $color_font2;
            overflow: hidden;
            h1,h2,h3,h4,h5,h6{
                font-weight: 500;
                color: $color_font1;
            }
        }
    }
    .container{
        position: relative;
        z-index: 2;
        > .row{
            .separate{
                padding-bottom: 70px;
                margin-bottom: 70px;
                border-bottom: 1px solid #e3e3e3;
                @media only screen and (max-width: 1500px) {
                    padding-bottom: 40px;
                    margin-bottom: 40px;
                }
            }
            &:last-child{
                .separate{
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    border-bottom: 0;
                }
            }
        }
    }
}

.download_list{
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 40px;
    }
    > ul{
        > li{
            margin-bottom: 30px;
            &:hover{
                a{
                    text-decoration: none;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.download_box{
    position: relative;
    z-index: 2;
    .icon_svg{
        position: absolute;
        top: 0;
        height: 100%;
        @include vertical;
    }
    > .icon_svg{
        left: 0;
    }
    &:hover{
        .download_box_content{
            .default_font {
                color: $color1 ;
                span {
                    color: $color1 ;
                }
            }
        }

    }
    //&:before{
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //    content: '';
    //    width: 30px;
    //    height: 100%;
    //    background-repeat: no-repeat;
    //    background-position: left center;
    //    background-image: url("./../template_img/d_icon1.png");
    //}
    .download_box_content{
        border: 2px solid $border-color;
        width: calc(100% - 10px);
        padding-left: 45px;
        padding-right: 80px;
        padding-top: 18px;
        padding-bottom: 18px;
        margin-left: 10px;
        //background-repeat: no-repeat;
        //background-position: right 30px center;
        //background-image: url("./../template_img/d_icon2.png");
        > .icon_svg{
            right: 30px;
        }
        .default_font{
            color: $color_font1;
            text-transform: uppercase;
            @include anim;
            p{
                font-weight: 600;
            }
            span{
                @include anim;
                color: $color_font2;
                text-transform: lowercase;
            }
        }
    }
}

.article_info_box{
    background: $color_white;
    border: 1px solid #f2eee9;
    padding: 28px 60px;
    box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.05);
    @media only screen and (max-width: 1500px) {
        padding: 28px;
    }
    @media only screen and (max-width: 767px) {
        padding: 20px;
        text-align: center;
        .inline_row{
            > div{
                width: 100%;
                &.text-right{
                    text-align: center;
                    padding-top: 15px;
                }
            }
        }
    }

    .default_font{
        color: $color_font2;
        line-height: 30px;
        span{
            color: $color_font1;
        }
    }
    .btns{
        .inner_holder{
            > div{
                display: inline-block;
                vertical-align: middle;
            }
        }
        font-size: 13px;
        div, .print_btn{
            font-weight: 700;
            text-transform: uppercase;
        }
        > div{
            display: inline-block;
            vertical-align: middle;
            //margin-right: 60px;
            margin-right: 30px;
            @media only screen and (max-width: 991px) {
                margin-right: 20px;
            }
            @media only screen and (max-width: 500px) {
                margin-right: 0;
                width: 100%;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            .fb_btn{
                font-size: 12px;
                font-weight: 800;
                line-height: 35px;
                display: inline-block;
                color: $color_white;
                background-color: #3a5a97;
                border-radius: 6px;
                padding-left: 55px;
                padding-right: 30px;
                background-repeat: no-repeat;
                background-position: left 30px center;
                background-image: url("./../template_img/fb_btn_icon.png");
                margin-left: 12px;
            }
            .fb_share_btn{
                font-size: 12px;
                font-weight: 800;
                line-height: 35px;
                display: inline-block;
                color: $color_white;
                background-color: #3a5a97;
                border-radius: 6px;
                padding-left: 55px;
                padding-right: 30px;
                background-repeat: no-repeat;
                background-position: left 30px center;
                background-image: url("./../template_img/fb_btn_icon.png");
                margin-left: 12px;
            }
            .print_btn{
                line-height: 35px;
                display: inline-block;
                @include anim;
                color: $color_font1;
                padding-left: 15px;
                //padding-left: 50px;
                //background-repeat: no-repeat;
                //background-position: left center;
                //background-image: url("./../template_img/print_icon.png");
                &:hover{
                    text-decoration: none;
                    color: $color1;
                }
            }
        }
    }
}

.mobile_hide{
    @media only screen and (max-width: 991px) {
        display: none !important;
    }
}

.main_download{
    .container{
        > .row{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.main_download_box{
    background: $color_white;
    padding: 20px;
    display: flex;
    @include anim;
    position: relative;
    > .icon_svg{
        position: absolute;
        height: 100%;
        @include vertical;
        right: 30px;
        top: 0;
        @media only screen and (max-width: 991px) {
            -ms-flex-align: flex-end;
            -webkit-align-items: flex-end;
            -webkit-box-align: flex-end;

            align-items: flex-end;
            padding-bottom: 35px;
        }
    }
    @media only screen and (max-width: 991px) {
        display: block;
    }
    &:hover{
        background: $color1;
        .desc{
            //background-image: url("./../template_img/d_icon4w.png");
            .underline_header{
                .default_font{
                    h3{
                        color: $color_white;
                    }
                }
                &:after{
                    background: $color_white;
                }
            }
            .default_font{
                color: $color_white;
            }
        }
    }
    .file_type{
        position: relative;
        background-color: $color3;
        width: 140px;
        height: 110px;
        @include bg_pos;
        //background-image: url("./../template_img/typ_icon.png");
        clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%);
        @include vertical;
        > div{
            width: 100%;
            text-align: center;
            > div{
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
            height: 180px;
        }
        div.image{
            @include fill;
            @include bg_pos;
            background-size: cover;
        }
    }
    .desc{
        width: calc(100% - 140px);
        padding-left: 30px;
        padding-right: 70px;
        //background-repeat: no-repeat;
        //background-position: right 30px center;
        //background-image: url("./../template_img/d_icon4.png");
        @include vertical;
        @media only screen and (max-width: 991px) {
            width: 100%;
            padding-left: 0;
            background-position: right center;
            padding-top: 20px;
        }
        .default_font{
            @include anim;
        }
        .underline_header{
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 13px;
            &:after{
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                width: 30px;
                height: 2px;
                background: $color1;
                @include anim;
            }
            .default_font{
                h3{
                    @include anim;
                    color: $color_font1;
                    font-weight: 600;
                }
            }
        }
        > div{
            width: 100%;
        }
    }
}
.contact{
    background: $color3;
    .contact_data_list{
        @media only screen and (max-width: 767px) {
            margin-bottom: 30px;
            text-align: center;
        }
        > ul{
            > li{
                color: $color_font1;
                margin-bottom: 40px;
                //padding-left: 50px;
                background-repeat: no-repeat;
                background-position: left top;
                min-height: 33px;
                font-size: 21px;
                line-height: 30px;
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &:last-child{
                        width: calc(100% - 32px);
                        padding-left: 20px;
                    }
                }
                @media only screen and (max-width: 1500px) {
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 30px;
                }
                @media only screen and (max-width: 1200px) {
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 25px;
                }
                @media only screen and (max-width: 767px) {
                    > div{
                        &:first-child{
                            display: block;
                            margin: 0 auto 10px auto;
                        }
                        &:last-child{
                            padding-left: 0;
                        }
                    }
                }
                //&.adress{
                //    background-image: url("./../template_img/main_contact_icon1.png");
                //}
                //&.phone{
                //    background-image: url("./../template_img/main_contact_icon2.png");
                //}
                //&.mail{
                //    background-image: url("./../template_img/main_contact_icon3.png");
                //}
                //&.open{
                //    background-image: url("./../template_img/main_contact_icon4.png");
                //}
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    @include anim;
                    color: inherit;
                    &:hover{
                        text-decoration: none;
                        color: $color1;
                    }
                }
            }
        }
    }
}
.contact_data{
    background: $color_white;
    padding: 60px;
    margin-bottom: 60px;
    @media only screen and (max-width: 1500px) {
        padding: 40px;
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 767px) {
        padding: 30px;
    }
    .image{
        width: 100%;
        height: 0;
        padding-bottom: 44.445%;
        @include bg_pos;
        background-size: cover;
        @include corner;
        @media only screen and (max-width: 991px) {
            padding-bottom: 74%;
        }
    }
}
.contact_form_box{
    background: $color_white;
    padding: 60px;
    margin-bottom: 60px;
    @media only screen and (max-width: 1500px) {
        padding: 40px;
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 767px) {
        padding: 30px;
    }
    .desc{
        color: $color_font1;
        font-size: 21px;
        line-height: 30px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 26px;
        }
        p{
            margin-bottom: 30px;
            @media only screen and (max-width: 767px) {
                margin-bottom: 15px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

input.btn1{
    height: 60px;
    border: none;
    @media only screen and (max-width: 1200px) {
        width: 100%;
    }
}
.form_box{
    padding-left: 70px;
    @media only screen and (max-width: 991px) {
        padding-left: 0;
        padding-top: 50px;
    }
    .col-lg-5{
        &.text-right{
            @media only screen and (max-width: 1200px) {
                width: 100%;
                padding-top: 30px;
            }
        }
    }
    label{
        color: $color_font2;
        font-weight: 400;
    }
    form{
        > .row{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .default_form{
        @media only screen and (max-width: 767px) {
            .inline_row{
                > .col-xs-6{
                    width: 50% !important;
                    &.text-right{
                        padding-top: 0;
                    }
                }
            }
        }
        .row{
            margin-bottom: 5px;
            .text-right{
                text-transform: uppercase;
                color: #b8b8b8;
                font-size: 13px;
                @media only screen and (max-width: 1200px) {
                    font-size: 11px;
                }
                p{
                    font-weight: 600;
                }
            }
        }
    }
}

.recaptcha{
    padding: 10px 0 30px 0;
    @media only screen and (max-width: 1200px) {
        padding: 10px 0 15px 0;
    }
}

.rules{
    .default_form{
        padding-top: 0;
    }
    > div{
        display: inline-block;
        vertical-align: top;
        &.check_desc{
            width: calc(100% - 20px);
            padding-left: 17px;
            font-size: 13px;
            color: $color_font1;
            padding-top: 1px;
            font-weight: 500;
            a{
                text-decoration: underline;
                font-weight: 500;
                color: $color1;
            }
        }
    }
}

.toggle_box{
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
    &.open{
        .toggle_top{
            background-color: $color1;
            color: $color_white !important;
            background-image: url("./../template_img/sm_select_arr2.svg");
        }
    }
    .toggle_top{
        @include anim;
        text-align: left;
        width: 100%;
        background-color: $color_white;
        padding: 18px 60px 18px 30px;
        font-weight: 500;
        background-repeat: no-repeat;
        background-position: right 30px center;
        background-image: url("./../template_img/sm_select_arr.svg");
        &.default_font{
            color: $color_font1;
        }
    }
    .toggle_bottom{
        display: none;
        border: 1px solid #f6f6f6;
        padding: 30px;
        box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.05);
        background: $color_white;
        .toggle_contact_box{
            margin-bottom: 25px;
            .default_font{
                color: $color_font1;
                p{
                    font-weight: 700;
                    span{
                        font-weight: 400;
                        color: $color_font2;
                    }
                }
            }
            .footer_contact_box{
                padding-top: 15px;
                font-size: 16px;
                p,a{
                    font-weight: 500;
                    color: $color_font2;
                }
                a{
                    color: $color_font2;
                    @include anim;
                    &:hover{
                        text-decoration: none;
                        color: $color1;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.map_box{
    background: $color_black;
    width: 100%;
    height: 0;
    padding-bottom: 32.24%;
    position: relative;
    @media only screen and (max-width: 767px) {
        padding-bottom: 250px;
    }
    &:hover{
        iframe{
            opacity: 1;
        }
    }
    iframe{
        border: none;
        @include anim;
        @include fill;
        opacity: 0.9;
    }
}

.err_txt {
    color:$color_err;
}

.myajaxlist_paginate_box {
    width: 100%;
    padding: 14px 42px;
    display: flex;
    margin-top: 50px;
    box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.05);
    background:$color_white;
    //margin-bottom: 100px;
    @media only screen and (max-width: 1500px) {
        margin-top: 25px;
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 50px;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    .arrow_box {
        width: 148px;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        > div {
            line-height: 28px;
            letter-spacing: 2px;
            font-weight: 500;
            padding:0!important;
            font-size: 11px;
            text-transform: uppercase;
            padding-top:2px!important;
            height:30px!important;
        }

        &.left_arr{
            > div {
                border-right: 1px solid #d7d9db!important;
                text-align: left!important;
                padding-left: 30px!important;
                background-position: left center!important;
                background-image: url("/turist_img/pagi_arr_left.png")!important;

                @media only screen and (max-width: 768px) {
                    text-align: center;
                    border-right: none;
                    border-bottom: 1px solid #d7d9db;
                    padding-bottom: 10px;
                }
            }
        }
        &.right_arr{
            > div {
                border-left: 1px solid #d7d9db!important;
                text-align: right!important;
                padding-right: 30px!important;
                background-position: right center!important;
                background-image: url("/turist_img/pagi_arr_right.png")!important;

                @media only screen and (max-width: 768px) {
                    text-align: center;
                    border-left: none;
                    border-top: 1px solid #d7d9db;
                    padding-top: 10px;
                }
            }
        }
    }
    .pages_box{
        width: calc(100% - (2* 148px));
        text-align: center;
        @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 10px 0;
        }
        .one_page {
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            color: $color_font2;
            font-size: 15px;
            background-color: #fff!important;
            width:auto;
            height:30px!important;
            line-height: 30px!important;
            padding-top:0!important;


            &:last-child{
                margin-right: 0;
            }
            &.active{
                color: $color_font1!important;
                font-weight: 700;
                background-color:none;
            }
        }
    }
}

.icon_svg {
    path {
        fill: $color1;
    }
}

.icon_header {
    path {
        fill: $header_font_colour;
    }
}

.devs{
    span{
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.mycalendar2_slider .slide .day.active{
    background: $color1;

}
.main_events .calendar .mycalendar2_slider .slide.days .day.active .name{
    color: $color_white;
}
.main_events .calendar .mycalendar2_slider .slide.days .day.active .no{
    color: $color_white;
}
.mycalendar2_slider .special_bg_color{
    background: $color1;
}

.mycalendar2_datepicker .popup_html:after{
    background: $color1 !important;
}
.mycalendar2_datepicker .popup_html ul li{
    background: $color1 !important;
}

.main_events{
    .pagination{
        margin: 0 !important;
    }
    .paggination_box{
        height: auto !important;
        width: 100%;
        padding: 14px 42px;
        display: flex !important;
        border: 1px solid #f2eee9;
        margin-top: 50px;
        box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.05);
        @media only screen and (max-width: 1500px) {
            margin-top: 25px;
        }
        @media only screen and (max-width: 768px) {
            display: block !important;
        }
        li{


            button{
                width: 100%;
                font-size: 11px;
                text-transform: uppercase;
                color: $color_font2;
                > span{
                    display: inline-block;
                    background-repeat: no-repeat;
                    line-height: 28px;
                    letter-spacing: 2px;
                }
            }
        }
        #pagi_arr_left{
            width: 148px;
            border-right: 1px solid #d7d9db;
            @media only screen and (max-width: 768px) {
                width: 100%;
                border: 0;
            }
            a{

                text-align: left;
                width: 100%;
                font-size: 11px;
                text-transform: uppercase;
                color: $color_font2;
                > span{
                    display: inline-block;
                    background-repeat: no-repeat;
                    line-height: 28px;
                    letter-spacing: 2px;
                }
                @media only screen and (max-width: 768px) {
                    text-align: center;
                    border-right: none;
                    padding-bottom: 10px;
                }
                > span{
                    padding-left: 30px;
                    background-position: left center;
                    background-image: url("./../turist_img/pagi_arr_left.png");
                    background-repeat: no-repeat;
                }
            }
        }
        #pagi_arr_right{
            width: 148px;
            border-left: 1px solid #d7d9db;
            @media only screen and (max-width: 768px) {
                width: 100%;
                border: 0;
            }
            a{

                text-align: right;
                width: 100%;
                font-size: 11px;
                text-transform: uppercase;
                color: $color_font2;
                > span{
                    display: inline-block;
                    background-repeat: no-repeat;
                    line-height: 28px;
                    letter-spacing: 2px;
                }
                @media only screen and (max-width: 768px) {
                    text-align: center;
                    border-left: none;
                    padding-top: 10px;
                }
                > span{
                    padding-right: 30px;
                    background-position: right center;
                    background-image: url("./../turist_img/pagi_arr_right.png");
                    background-repeat: no-repeat;
                }
            }
        }
        .pagi_sites{
            width: calc(100% - (2* 148px));
            text-align: center;
            @media only screen and (max-width: 768px) {
                width: 100%;
                padding: 10px 0;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 30px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        a{
                            background: transparent;
                            color: $color_font1;
                            font-weight: 700;
                        }
                    }
                    a{
                        color: $color_font2;
                        font-size: 15px;
                        line-height: 28px;
                        display: block;
                        border: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

.file_extension {
    color: $color1;
}
